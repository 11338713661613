@value primary, secondary, gris from '../../../../index.module.css';

.transporte {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  z-index: 20;
  width: 40px;
  border-radius: 5px;
  border: 5px solid rgb(255, 179, 0);
  cursor: pointer;
  background-image: linear-gradient(0deg, transparent 30%, #ffffff 60%);
  background-size: 10px 10px;
  background-color: rgba(118, 118, 118, 0.8);
}

.transporteDark{
  filter: brightness(0.8) !important;
  background-image: linear-gradient(0deg, transparent 30%, #ffffff 80%) !important;
  background-color: #f0f8ff00 !important;

}

.sinCarga{
  visibility: hidden;
}

.palletCarga {
  width: 40px;
  height: auto;
  border-radius: 4px;
  pointer-events: none;
  border: 1px solid grey;
  border-radius: 2px;
  /* transform: translateY(275px); */
  /* transition: all 3s linear; */
  /* animation-duration: 10s;
  animation-name: moveCarga;
  animation-iteration-count: infinite; */
}

.transporte:has(> .palletListoCarga){
 justify-content: flex-end; 
}
.transporte:has(> .palletListoDescarga){
  justify-content: flex-start; 
 }
 

.palletDescarga {
  width: 40px;
  height: auto;
  border-radius: 4px;
  pointer-events: none;
  border: 1px solid grey;
  border-radius: 2px;
  /* transform: translateY(-275px); */
  /* transition: all 3s linear; */
  /* animation-duration: 10s;
  animation-name: moveDescarga;
  animation-iteration-count: infinite; */
}

.palletListoCarga {
  width: 40px;
  height: auto;
  border-radius: 4px;
  pointer-events: none;
  animation-duration: 1.5s;
  animation-name: destelloCarga;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  /* transform: translateY(275px); */
}

.palletListoDescarga {
  width: 40px;
  height: auto;
  border-radius: 4px;
  pointer-events: none;
  animation-duration: 1.2s;
  animation-name: destelloCarga;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  transform: translateY(-5px);
}

.tultip{
  background-color: #E8AA42;
}

/* 
.transporte:hover > .palletCarga {
    transform: translateY(275px);
    /* transition: all 3s linear; */
/* animation-duration: 10s; */
/* animation-name: moveCarga; */
/* animation-iteration-count: infinite; */
/* } */

/* Animación para carga */
@keyframes moveCarga {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(280px);
  }
}

/* Animacion para descarga */
@keyframes moveDescarga {
  from {
    transform: translateY(270px);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes destelloCarga {
  from {
    /* box-shadow: 0px 0px 0px 0px rgba(255,179,0,0.75);
    -webkit-box-shadow: 0px 0px 0px 0px rgba(255,179,0,0.75);
    -moz-box-shadow: 0px 0px 0px 0px rgba(255,179,0,0.75); */
    box-shadow: 0px 0px 8px 8px #E8AA42, inset 0px 0px 3px 2px #fff;
  }

  to {
    /* box-shadow: 1px 1px 15px 6px rgba(255, 179, 0, 0.75);
    -webkit-box-shadow: 1px 1px 15px 6px rgba(255, 179, 0, 0.75);
    -moz-box-shadow: 1px 1px 15px 6px rgba(255, 179, 0, 0.75); */
    box-shadow: 0px 0px 0px 0px #E8AA42, inset 0px 0px 0px 0px #fff;
  }
}
