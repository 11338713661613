.wrapperIcon {
  cursor: pointer;
 }

 .wrapperIcon:hover{
  color:rgb(25, 118, 210);
}

.wrapperInput {
  display: flex;
  align-items: center;
  gap:10px;
}

.canvas{
  top: 13vh !important;
  background-color: rgba(66, 66, 66, 0.935) !important;
  color: white !important;
  width: 20vw !important;
}

.canvaBody{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* padding: 0 !important; */
  gap: 3%;
}
.canvaBody p{
  margin-bottom: 0 !important;
}

.canvasDescarga{
width: 25vw !important;
top: 13vh !important;
/* background-color: rgba(66, 66, 66, 0.935) !important;
color: white !important; */
}

.canvasDescargaDark{
  background-color: #292929 !important;
  color: #ffffffb6 !important;
}

.canvasDescargaDark select, .canvasDescargaDark input, .canvasDescargaDark textarea {
  background: #46afaf6b !important;
  border-color: #ffffff61 !important;
  color: #ffffffb6 !important;
}

.canvasDescargaDark option{
  background: #46afaf !important;
  color: #000 !important;
}

.canvasDescargaDark ::placeholder{
  color: #ffffffb6 !important;
}

.wrapperTitle{
display: flex;
align-items: center;
gap: 10px;
}