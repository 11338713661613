.modalInfo{

}

.modalInfo{
    left: -12.5vw !important;
}
.modalInfoDark{
    background-color: #292929 !important;
    color: #ffffffb6 !important;
    left: -12.5vw !important;
    max-height: 73vh !important;
}
.fondo{
    background: none !important;
}
.titulo{
    border-color: #46afaf6b !important;
}

.seccion{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-bottom: 3rem;
}

.tituloSeccion{
    text-decoration: underline;
    text-underline-offset: 0.2rem;
    text-decoration-color: #46afaf6b;
    margin: auto;
}

.divIcon{
    display: flex;
    flex-direction: column;
    font-size: 0.8rem;
    align-items: center;
    justify-content: flex-end;
}

.customIcon{
    width: 4rem;
    height: 3rem;
    border: 2px solid;
    border-bottom: 4px solid;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    letter-spacing: 2px;
}

.customIconDark{
    border-color: white;
}

.singleLine{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}

.icons{
    font-size: 2rem;
}

.seccion strong{
    padding-right: 10px;
    font-weight: 300;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.enumerados{
    display: flex;
    flex-direction: row;
}

.luzActivo{
    width: 10px;
    height: 10px;
    border-radius: 10px;
    margin:0 0.7rem;
    box-shadow: 0px 0px 2px 1px rgb(0, 255, 81), inset 0px 0px 2px 2px rgb(0, 255, 81) !important;
    background-color: rgb(99, 240, 144) !important;
    border: 1px solid rgb(1, 164, 52) !important;
  }


  .luzActivoError{
    width: 10px;
    height: 10px;
    border-radius: 10px;
    margin:0 0.7rem;
    box-shadow: 0px 0px 2px 1px red, inset 0px 0px 2px 2px red !important;
    background-color: #ff4646 !important;
    border: 1px solid #790000 !important;
  }

.bodyModal::-webkit-scrollbar-thumb {
    /* background: rgba(167, 167, 167, 0.316); */
    border-radius: 0 0 5px 0;
  }
.bodyModal::-webkit-scrollbar-track {
    border-radius: 0 0 7px 0;

}
