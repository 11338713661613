@value primary, secondary,empty_box gris from './../../index.module.css';

.container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  height: 87vh;
  position: relative;
}

.grilla {
  position: relative;
  grid-column: 1 / span 9;
  justify-content: center;
  width: -webkit-fill-available;
  height: 82vh;
  overflow: auto;
  /* align-items: center; */
  display: flex;
  flex-direction: column;
  background-image: url(./../../assets/imgs/dust.png);
  filter: brightness(0.9);
}
.grillaDark {
  background-image: url(./../../assets/imgs/dustDark.png) !important;
}
.grilla::-webkit-scrollbar-thumb {
  background: rgba(167, 167, 167, 0.316);
}
.grilla::-webkit-scrollbar {
  width: 1rem;
}
.grilla::-webkit-scrollbar-track {
  background: rgba(217, 217, 217, 0.066);
}
.grilla::-webkit-scrollbar-thumb:hover {
  background: rgba(66, 66, 66, 0.572);
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}
.grilla::-webkit-scrollbar-corner {
  background-color: rgba(66, 66, 66, 0.331);
}

.barraInf {
  grid-column: 1 / span 9;
  width: -webkit-fill-available;
  height: 5vh;
  background-color: rgba(118, 118, 118, 0.8);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
}

.barraInfDark {
  background-color: #121212e6 !important;
}

.panel {
  /* min-width: 500px; */
  grid-column: 10 / 13;
  grid-row: 1 / span 2;
  height: 87vh;
  overflow-y: auto;
  display: flex;
  flex-direction: row;
  padding: 1rem;
  gap: 1rem;
  border-left: 2px solid gris;
  /* width: -webkit-fill-available;
    height: -webkit-fill-available; */
}
.panelDark {
  /* min-width: 500px; */
  grid-column: 10 / 13;
  grid-row: 1 / span 2;
  height: 87vh;
  overflow-y: auto;
  display: flex;
  flex-direction: row;
  padding: 1rem;
  gap: 1rem;
  border-left: 2px solid gris;
  background-color: #121212e6;
  color: #ffffffb6 !important;
  border-left: 1px solid #bebebe8f;
}
