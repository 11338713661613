.allCardDark{
  border-color: #bebebe8f !important;
  color: #ffffffb6 !important;
}

.headerCard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 300;
}
.headerCardDark{
  border-color: #bebebe8f !important;
  background: #46afaf6b !important;
}

.cardBody {
  display: grid !important;
  grid-template-columns: repeat(3, 1fr) !important;
  justify-items: center;
  gap: 1rem;
}

.cardBodyDark{
  filter: grayscale(0.30);
}

.seccionBody {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
}

.paradaEmergencia {
  
  /* border: 1px solid white; */
  /* animation-duration: 1s; */
  /* animation-name: paradaActiva; */
  animation: paradaActiva 1s alternate-reverse infinite;
  /* animation-iteration-count: infinite; */
  /* animation-direction: alternate; */
  /* animation-timing-function: linear; */
}

.btnActivado {
  box-shadow: inset 0px 0px 3px 2px #1bff95;
  background: rgb(32, 130, 130) !important;
}

.muestraColor{
  width: 10px;
  height: 10px;
  border: 1px solid grey;
  border-radius: 10px;
  margin-left: 0.7rem;
}
.muestraColorDark{
  width: 10px;
  height: 10px;
  border: 1px solid grey !important;
  /* border: none !important; */
  border-radius: 10px;
  margin-left: 0.7rem;
}

.activo{
  box-shadow: 0px 0px 2px 1px rgb(0, 255, 81), inset 0px 0px 2px 2px rgb(0, 255, 81) !important;
  background-color: rgb(99, 240, 144) !important;
  border: 1px solid rgb(1, 164, 52) !important;
}
.activoError{
  box-shadow: 0px 0px 2px 1px red, inset 0px 0px 2px 2px red !important;
  background-color: #ff4646 !important;
  border: 1px solid #790000 !important;
}

.buttonEstados{
  display: flex !important;
  justify-content: space-between !important;
}
.buttonEstadosDark{
  display: flex !important;
  justify-content: space-between !important;
  color: rgba(255, 255, 255, 0.879) !important;
}

.grupoBoton{
  pointer-events: none !important;
  background-color: rgba(0, 0, 0, 0.03) !important;
}

@keyframes paradaActiva {
  from {
    background: rgb(172, 0, 0);
    box-shadow: 0px 0px 0px 0px rgb(172, 0, 0),
    0px 0 0 0px rgba(0, 0, 0, 0.1), inset 0px 0px 0px 0px rgb(249, 137, 137);
  }

  to {
    /* box-shadow: inset 0px 0px 22px -3px rgb(102 0 0); */
    box-shadow: 0px 0px 8px 6px rgb(172, 0, 0),
      0px 10px 15px -3px rgba(0, 0, 0, 0.1), inset 0px 0px 7px 2px rgb(249, 137, 137);
    background: rgb(172, 0, 0);
  }
}
