@value primary, secondary from '../../../../index.module.css';

.colorPickerWrapper {
  display: flex;
  gap: 5px;
  margin: 0;
  padding: 0;
  align-items: center;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 64px;
  padding: 6px 16px;
}

.wrapper {
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: space-between;
}

.colorPickerWrapper > span {
  font-weight: 500;
}

.modalXxl {
  max-width: fit-content !important;
  width: 90vw !important;
}

.contentXXLDark {
  background-color: rgb(41 41 41) !important;
}

.icono {
  width: 2rem;
  height: 2rem;
  fill: secondary;
}
.iconoDark {
  fill: #bebebe8f !important;
}

.icono:hover {
  color: primary !important;
  fill: primary !important;
}

.customAlert {
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
  font-style: oblique;
  margin-left: 10px;
}
