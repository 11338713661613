.floatingBox {
  position: absolute;
  right: 20px;
  top: 20px;
  /* right: 20px; */
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px 25px;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  max-height: fit-content;
  z-index: 999;
  pointer-events: none;
  width: max-content;
}

.boxContent {
  margin: 0;
  text-align: center;
}
