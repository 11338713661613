.editDark{
    background-color: #292929 !important;
    color: #ffffffb6 !important;
}

.titleDark{
    color: #ffffffb6 !important;
}

.MuiDialog-paper{
    background-color: #292929 !important;
}

::-webkit-scrollbar-thumb {
    background: rgba(167, 167, 167, 0.316);
  }
::-webkit-scrollbar {
    width: 1rem;
  }
::-webkit-scrollbar-track {
    background: rgba(217, 217, 217, 0.066);
  }
::-webkit-scrollbar-thumb:hover {
    background: rgba(66, 66, 66, 0.572);
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  }
  ::-webkit-scrollbar-corner{
    background-color: rgba(66, 66, 66, 0.331);
  }