.wrapperIcon {
  cursor: pointer;
}

.wrapperIcon:hover {
  color: rgb(25, 118, 210);
}

.wrapperInput {
  display: flex;
  align-items: center;
  gap: 10px;
}

.canvas {
  top: 13vh !important;
  background-color: rgba(66, 66, 66, 0.935) !important;
  color: white !important;
  width: 20vw !important;
}

.canvaBody {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* padding: 0 !important; */
  gap: 3%;
}
.canvaBody p {
  margin-bottom: 0 !important;
}

.canvasDescarga {
  width: 25vw !important;
  top: 13vh !important;
  /* background-color: rgba(66, 66, 66, 0.935) !important;
color: white !important; */
}

.canvasNot {
  width: 20vw !important;
  top: 13vh !important;
  right: 25vw !important;
  background-color: #f5f5f5 !important;
  height: 80.3%;
  transform: translateX(20rem);
}

.canvasDescargaDark {
  background-color: #292929 !important;
  color: #ffffffb6 !important;
}

.canvasDescargaDark select,
.canvasDescargaDark input,
.canvasDescargaDark textarea {
  background: #46afaf6b !important;
  border-color: #ffffff61 !important;
  color: #ffffffb6 !important;
}

.canvasDescargaDark option {
  background: #46afaf !important;
  color: #000 !important;
}

.canvasDescargaDark ::placeholder {
  color: #ffffffb6 !important;
}

.wrapperTitle {
  display: flex;
  align-items: center;
  gap: 10px;
}

.containerTransporte {
  width: 100%;
  /* display: grid; */
  /* grid-template-columns: [first] 30% [line2] 70% ; */
  /* grid-template-rows: repeat(7, 5rem [row-start]); */
  position: relative;
  display: flex;
  flex-direction: column;
}

.transporte {
  width: 100%;
  display: flex;
  flex-direction: row;
  /* position: relative; */
  /* border: 2px 10px;
  border-color: #000;
  border-style: solid; */
}

.trs {
  grid-column: 1 / span 1;
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999;
  font-weight: 600;
  /* height: 100px; */
  /* background-color: red; */
  /* border: 1px solid black; */
}

.trsName {
  background-color: white;
  border-radius: 5px;
}
.trsNameDark {
  background-color: #292929;
}

.icono {
  width: 3rem !important;
  height: 3rem !important;
}

.iconoDark {
  width: 3rem !important;
  height: 3rem !important;
  fill: white;
}

.datosTrs {
  grid-column: 2 / span 2;
  width: 70%;
  height: fit-content;
  /* background-color: rgb(46, 41, 190); */
  /* border: 1px solid black; */
  /* border-bottom: 2px solid; */
  /* border-left: 2px solid; */
  padding: 5px;
}

.pallet {
  width: 4vw;
  height: 4vw;
  font-size: 8rem;
  border-radius: 3px;
  background-color: white;
  /* border: 1px solid black; */
}

.palletDark {
  background-color: #292929;
  fill: #ffffffb6;
}

.lineaGrafica {
  width: 5px;
  height: 95%;
  position: absolute;
  left: 14.6%;
  background-color: #ffc107;
  bottom: 0;
}

.iconoDireccion {
  position: absolute;
  bottom: 0;
  width: 2.4rem;
  height: 2.4rem;
}

.seccionBody {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
}

.muestraColor {
  width: 10px;
  height: 10px;
  border: 1px solid grey;
  border-radius: 10px;
  margin-left: 0.7rem;
}
.muestraColorDark {
  width: 10px;
  height: 10px;
  border: 1px solid grey !important;
  /* border: none !important; */
  border-radius: 10px;
  margin-left: 0.7rem;
}

.grupoBoton {
  pointer-events: none !important;
  background-color: rgba(0, 0, 0, 0.03) !important;
}

.activo {
  box-shadow: 0px 0px 2px 1px rgb(0, 255, 81), inset 0px 0px 2px 2px rgb(0, 255, 81) !important;
  background-color: rgb(99, 240, 144) !important;
  border: 1px solid rgb(1, 164, 52) !important;
}
.activoError {
  box-shadow: 0px 0px 2px 1px red, inset 0px 0px 2px 2px red !important;
  background-color: #ff4646 !important;
  border: 1px solid #790000 !important;
}

.buttonEstados {
  display: flex !important;
  justify-content: space-between !important;
}
.buttonEstadosDark {
  display: flex !important;
  justify-content: space-between !important;
  color: rgba(255, 255, 255, 0.879) !important;
}
