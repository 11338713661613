
.formDark {
  background: #46afaf6b !important;
  color: #ffffffb6 !important;
  border-color: #bebebe8f !important;
}
.formDark option {
  background: #46afaf !important;
  color: #000 !important;
}

.detalleFiltro {
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  margin-top: 1rem;
  border-top: 1px solid #bebebe8f;
  max-height: 32vh;
  overflow-y: auto;
}

.itemFiltro {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.itemFiltro span {
  font-weight: 500;
}

.muestraColor {
  width: 15px;
  height: 15px;
  border: 1px solid grey;
  border-radius: 5px;
}

.muestraColorDark {
  width: 15px;
  height: 15px;
  border: none !important;
  border-radius: 5px;
}

.detalleFiltro::-webkit-scrollbar-thumb {
  background: rgba(167, 167, 167, 0.7);
}
.detalleFiltro::-webkit-scrollbar {
  width: 0.8rem;
}
.detalleFiltro::-webkit-scrollbar-track {
  background: rgba(217, 217, 217, 0.3);
}
.detalleFiltro::-webkit-scrollbar-thumb:hover {
  background: rgba(66, 66, 66, 0.7);
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}
