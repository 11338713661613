@value primary, secondary from './../../index.module.css';

.Navbar{
    background-color: secondary;
    height: 10vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* gap: 2rem; */
}

.nameButton{
    color: white;
    text-decoration: none;
    font-weight: 300;
    padding-bottom: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    /* gap: 5px; */
    width: 7rem;
    height: 100%;
    border-top: 0.5rem solid #424242
}
.nameButton:hover{
    /* transform: scale(1.1); */
    background-color: rgba(255, 255, 255, 0.222);
    color: white;
}

.icon{
    height: 5vh;
    max-width: 100%;
}

.sectionName{
    height: 3vh;
    background-color: #282828;
    color: primary;
    font-weight: 600;
    font-size: 0.8rem;
}

.logo{
    margin-left: 0.5rem;
    position: absolute;
    left: 0;
}

