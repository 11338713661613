.canvasDescargaDark select, .canvasDescargaDark input, .canvasDescargaDark textarea {
    background: #46afaf6b !important;
    border-color: #ffffff61 !important;
    color: #ffffffb6 !important;
  }

  .canvasDescargaDark option{
    background: #46afaf !important;
    color: #000 !important;
  }
  
  .canvasDescargaDark ::placeholder{
    color: #ffffffb6 !important;
  }