@value primary, secondary from '../../../../index.module.css';


/* DarkMode */
.allCard{
  background: none !important;
}

.allCardDark{
  background: none !important;
  border-color: #bebebe8f !important;
  color: #ffffffb6 !important;

}

.contenedorBotonTogle{
  border-top: 1px solid rgba(0, 0, 0, 0.175);
  display: flex;
  justify-content: center;
}

.contenedorBotonTogleDark{
  border-color: #bebebe8f !important;
}

.botonTogle{
  background-color: white;
  border: none !important;
  width: 95%;
  text-align: center;
  color: grey !important;
}
.botonTogleDark{
  background:none !important;
  color: #ffffffb6 !important;
}
.headerCard{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 300;
}
.headerCardDark{
  border-color: #bebebe8f !important;
  background: #46afaf6b !important;
}

/*  */

.bodyCard{
    max-height: 40vh;
    /* overflow-y: auto; */
}
.bodyCard::-webkit-scrollbar-thumb {
    background: rgba(167, 167, 167, 0.7);
  }
  .bodyCard::-webkit-scrollbar {
    width: 0.8rem;
  }
  .bodyCard::-webkit-scrollbar-track {
    background: rgba(217, 217, 217, 0.3);
  }
  .bodyCard::-webkit-scrollbar-thumb:hover {
    background: rgba(66, 66, 66, 0.7);
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  }