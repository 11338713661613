@value primary, secondary from './../../index.module.css';

.boton {
  /* position: absolute; */
  /* right: 0; */
  /* margin: 1rem; */
  border: none;
  cursor: pointer;
  background: none;
}
.boton:hover {
  border-radius: 10%;
  outline: 1px solid rgba(0, 0, 0, 0.247);
}
.boton,
a {
  text-decoration: none !important;
}

.boton,
a:hover {
  text-decoration: none;
  color: #ffffffb6 !important;
}

.icono {
  width: 2rem;
  height: 2rem;
  color: secondary;
}
.iconoDark {
  fill: #bebebe8f !important;
}

.icono:hover {
  fill: primary !important;
}

.canvas {
  top: 13vh !important;
  background-color: #292929 !important;
  color: #ffffffb6 !important;

  width: 15.5vw !important;
}

.canvaBody {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 !important;
}

.canvaBoton {
  border: none;
  background: none;
  color: #ffffffb6;
  font-weight: 600;
  font-size: 1.1rem;
  padding: 5% 0 5% 1rem;
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5%;
}

.canvaBoton:hover {
  background-color: rgb(90, 90, 90);
  border-right: 1rem solid rgb(34, 185, 185);
}

/* PRUEBA */

/* .navbar {
    background-color: #060b26;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
  }
  
  .menuBars {
    margin-left: 2rem;
    font-size: 2rem;
    background: none;
  }
  
  .navMenu {
    background-color: #060b26;
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
  }
  
  .navMenuActive {
    background-color: #060b26;
    width: 250px;
    height: 100%;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
    left: 0;
    transition: 350ms;
  }
  
  .navtext {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 8px 0px 8px 16px;
    list-style: none;
    height: 60px;
    color: #f5f5f5;
  }
  
  .navText a {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
  }
  
  .navText a:hover {
    background-color: #1a83ff;
  }
  
  .navMenuItems {
    width: 100%;
  }
  
  .navbarToggle {
    background-color: #060b26;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
  }
  
  span {
    margin-left: 16px;
  } */
