@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Medium.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Black.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

/* ---- Colores --- */
@value primary: #22B9B9;
@value secondary: #424242;
@value empty_box: rgb(217, 217, 217);
@value borde: rgb(34, 185, 185);
@value gris: rgba(118, 118, 118, 0.8);
@value box_anulado: orange;
@value box_bloqueado: #515151;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root{
  font-size: 16px;
}

body {
  margin: 0 auto;
  font-family: 'Roboto', Arial, Helvetica, sans-serif !important;
  font-weight: 200 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5 !important;
}

button {
  font-weight: 300 !important;
}

