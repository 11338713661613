@value primary, secondary from '../../../../index.module.css';



.select{
    /* background: none; */
    color: rgba(118, 118, 118, 0.8);
    border: 1px solid #4f4f4f;
    border-radius: 5px;
    height: 50%;
}

.selectDark{
    background: #46afaf6b !important;
    border-color: #ffffff61 !important;
    color: #ffffffb6 !important;
}

.optionDark{
    background: #46afaf !important;
    color: #000 !important;
}

.icono{
    width: 1.3rem;
    height: 1.3rem;
    color: white;
    cursor: pointer !important;
}

.icono:hover{
    transform: scale(1.2);
    transition: all 0.5s ease 0s;
}