.canvas{
    top: 13vh !important;
    background-color: rgb(41 41 41 / 98%) !important;
    color: white !important;
    min-width: 300px;
    width: 15vw !important;
    height: 82vh;
    border-bottom: 3px solid #383838;
  }
  
  .canvaBody{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 15% !important;
    /* padding: 0 !important; */
    gap: 3%;
  }
  .canvaBody p{
    margin-bottom: 0 !important;
  }
