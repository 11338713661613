.container {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  height: 87vh;
}
.containerDark {
  background-color: #121212e6;
  color: #ffffffb6 !important;
}
.containerWhite {
  background-color: #fff;
}
