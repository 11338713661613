.wrapperText {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #ffffffb6;
  width: 30vw;
  padding: 0 10px;
  white-space: nowrap;
}

p {
  margin: 0 !important;
  padding: 0 3px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.positionActual {
  display: flex;
  justify-content: space-between;
  color: #ffffffb6;
  width: 12vw;
}

.positionActual div {
  display: flex;
  justify-content: center;
  width: fit-content;
}
