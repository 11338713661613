@value primary, secondary from '../../../../index.module.css';

.barraLateral {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.barraIconos {
  width: min-content;
  display: flex;
  flex-direction: column;
  /* padding: 1rem; */
  gap: 1rem;
}

.botonBarra {
  /* margin: 1rem; */
  border: none;
  cursor: pointer;
  background: none;
}

.botonBarra:hover {
  border-radius: 10%;
  /* outline: 1px solid rgba(0, 0, 0, 0.247); */
}

.icono {
  width: 2rem;
  height: 2rem;
  fill: secondary;
}
.iconoDark {
  fill: #bebebe8f !important;
}

.icono:hover {
  color: primary !important;
  fill: primary !important;
}
