.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

}


.wrapper span{
    font-weight: 300;
}

.infoPosicion {
  padding-top: 1rem;
    margin-top: 1rem;
    border-top: 1px solid #bebebe8f;
}
