
.colorPicker {
    width: 100% !important; 
    height:100% !important; 
    margin:0 !important;
    padding: 0 !important;
    border: none !important;
    cursor: pointer;
    opacity: 0;
    position: absolute;
}

input[type="color"] {
	-webkit-appearance: none;
}
input[type="color"]::-webkit-color-swatch-wrapper {
	padding: 0;
}
input[type="color"]::-webkit-color-swatch {
    border-radius: 15%;
}