@value primary, secondary from '../../../../index.module.css';


.info{
    width: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    /* padding: 1rem; */
    justify-content: space-between;
}

